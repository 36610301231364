.Closable {
    &__title {
        cursor: pointer;

        & > * { display: inline-block; }

        &:after {
            $size: .6em;
            content: '';
            display: inline-block;
            position: relative;
            top: -.25em;
            width: $size / 2;
            height: $size / 2;

            @include respond-to(tablet) {
                width: $size;
                height: $size;
            }

            margin-left: .6em;

            border: 1px solid #333;
            border-top: none;
            border-left: none;
            transition: transform .2s;
        }
    }

    &__content {
        overflow: hidden;
    }

    &.closed {
        .Closable__title:after {
            transform: rotate(-45deg);
            transition: transform .2s;
        }

        .Closable__content {
            height: 0;
            margin-top: -30px;
            opacity: 0;
            transition: margin-top .3s, opacity .3s;
        }
    }

    &.open {
        .Closable__title:after {
            transform: rotate(45deg);
            transition: transform .2s;
        }

        .Closable__content {
            height: auto;
            margin-top: 0px;
            opacity: 1;
            transition: margin-top .3s, opacity .3s;;
        }
    }

}
