.AboutPage {

    h1 {
        margin: 75px auto 30px;
        @include respond-to(tablet) {
            margin: 100px auto 50px;
        }
    }

    .page-content {
        @include respond-to(mobile) {
            padding: $dims_padding / 4;
        }

        @include respond-to(lg-desktop) {
            //width: 80%;
        }
    }

    p {
        margin: 20px 0;

        @include respond-to(tablet) {
            margin: 40px 0;
        }

        img {
            max-width: 100%;
        }
    }

    b {
        font-family: $font_family-primary;
    }

    .references {
        padding-left: 0;
        list-style: none;

        li {
            margin: 1em 0;

            color: $color_brand-yellow;

            span {
                width: 90%;
                color: $color_font-dark;

                @include  respond-to(tablet) {
                    display: inline-block;
                    left: 2em;
                }
            }
        }
    }

    a {
        color: $color_font-dark;
        text-decoration: underline;
    }

    a.strategy-style {
        display: inline-block;

        //width: 90%;
        margin: .6em 0;
        padding: .2em 1em;

        font-family: $font_family-primary;
        font-size: $font_size-xsmall;
        text-align: center;
        text-decoration: none;

        border-radius: 15px;
        background: $color_brand-brown;
        color: $color_font-light;
    }

}
