.MapPage {

  height: 100vh;

  .threat-selector__menu {
    z-index: 2;
  }

  .logo-link {
    position: absolute;
    top: $dims_padding / 4;
    left: $dims_padding / 4;
    @include respond-to(tablet) {
      top: $dims_padding / 2;
      left: $dims_padding / 2;
    }
    z-index: 2;
  }

  .Navigation {
    position: absolute;
    top: $dims_padding / 4;
    right: $dims_padding / 4;
    @include respond-to(tablet) {
      top: $dims_padding / 2;
      right: $dims_padding / 2;
    }
    z-index: 2;
    background: rgba($color_bg-light, .9);
    padding: $dims_padding / 4;
  }

  a {
    color: $color_font-dark;
  }

  .threat-viewer {
    position: fixed;
    bottom: $dims_padding /4;
    left: 0;
    right: 0;
    margin: auto;
    @include respond-to(tablet) {
      bottom: $dims_padding / 2;
      left: initial;
      right: $dims_padding / 2;
    }

    width: 95%;
    max-width: 650px;
    max-height: 85px;
    @include respond-to(tablet) {
      max-height: 295px;
    }
    height: 90%;

    background: $color_bg-light;
    transition: max-height .5s;



    &.expanded {
      overflow-y: hidden;
      max-height: 85vh;
      transition: max-height .5s;

      .threat-content {
        padding: $dims_padding / 4;
        overflow-y: auto;

        h1 {
          margin-top: 0px;
          opacity: 1;
          transition: opacity .3s, margin-top .5s;
        }

        &-body {
          margin-top: 0;
          opacity: 1;

          transition: opacity 1s ease, margin-top 1s;
        }
      }
    }
  }

  .threat-content {
    z-index: 1;
    padding: $dims_padding / 8;
    height: 100%;

    @include respond-to(tablet) {
      padding: $dims_padding / 2;
      height: calc(100% - 38px);
    }


    .threat-body {
      display: none;
      @include respond-to(tablet) {
        display: block;
      }
    }
    //overflow: hidden;

    h1 {
      margin-top: -70px;
      opacity: 0;
      @include respond-to(mobile) {
        font-size: $font_size-medium * 1.4;
      }
      @include respond-to(tablet) {
        margin: -80px auto 16px;
      }
      transition: margin-top .5s, opacity .3s;
    }

    button {
      display: block;
      @include respond-to(tablet) {
        margin-top: $dims_padding / 2;
      }
    }

    &-body {
      margin-top: 100px;
      opacity: 0;
      padding-bottom: 150px;

      transition: opacity .1s, margin-top 1.5s;
    }
  }

  .threat-viewer-header {
    background: $color_brand-brown;
  }

  .threat-selector {
    width: 70%;
    z-index: 10;

    &__control {
      background: none;
      border: none;
      cursor: pointer;
      outline: none !important;
    }

    &__single-value {
      color: $color_font-light;
      font-size: $font_size-medium;
      font-family: $font_family-primary;
      font-weight: 500;
    }
  }

  .cross-link {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0 1.5em 1em 0.5em;
    //margin-right: 32px;
    background-color: white;
    z-index: 1;
  }

  .close-expanded {
    position: absolute;
    top: 10px;
    right: 5px;
    color: $color_font-light;
    font-weight: 600;
  }

  h2 {
    margin-bottom: 0;
  }

  h3 {
    margin-top: $dims_padding / 2;
  }

  h4 {
    margin-bottom: 5px;
  }

  p em {
    display: block;
    margin-bottom: -.5em;
  }

  h3 + p, h4 + p {
    margin-top: 0;
  }

  .Closable {
    margin-top: $dims_padding / 2;
    border: 1px solid $color_brand-brown;
    padding: $dims_padding / 4;

    h2 {
      margin: 0;
      padding-left: 10px;

      font-size: $font_size-xsmall;

      p {
        margin: 0;
      }
    }

    h3 {
      font-weight: 500;
      font-family: $font_family-secondary;
      font-size: $font-size-small;
      margin-top: .5em;
      margin-bottom: 5px;

      b {
        font-family: $font_family-primary;
      }
    }
  }

  blockquote {
    margin: 25px;

    span {
      font-style: italic;
    }
  }

  .Closable__title {
    margin-top: -$dims_padding / 2;
    background: $color_bg-light;
    font-size: $font_size-xsmall;

    &:after {
      top: -2px;
      margin-left: 5px;
      width: 5px;
      height: 5px;
      border-color: $color_brand-yellow !important;
      border-width: 2px;
    }
  }

  ol {
    padding-left: 1em;

    li {
      &::marker {
        color: $color_brand-yellow;
      }

      & + li {
        margin-top: 1em;
      }
    }
  }

}

.MapViewer {
  position: absolute;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;

  svg {
    fill: none;

    path {
      fill: none;
      stroke: $color_brand-red;
    }

  }

  $legend-bottom-mobile: 105px;
  .legend-toggle {
    position: absolute;
    max-width: 250px;
    background: white;
    padding: 1em;
    bottom: $legend-bottom-mobile;
    @include respond-to(tablet) {
      bottom: 0;
    }
    left: $dims_padding / 4;
  }

  .MapLegend {
    position: absolute;
    max-width: 250px;
    background: white;
    padding: 1em;
    bottom: $legend-bottom-mobile;
    @include respond-to(tablet) {
      bottom: 0;
    }
    left: 0;

    .legend {
      position: relative;

      .simple-scale {
        color: #999999;
        font-size: 0.9em;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;

        .top {
          position: absolute;
          top: 0;
          right: 0;
        }

        .bottom {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }


    .metric-info {
      font-size: 0.9em;
      margin-bottom: 1em;
    }

    .LegendItem {
      .item-name {
        display: inline-block;
        padding: 0.2em 0.5em;
      }

      .color-swatch {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 3px;
        background: red;
        border: 1px solid black;
      }

    }
  }
}

.mapboxgl-popup {
  @include respond-to(tablet) {
    z-index: 1;//in mobile mode, we don't want this covering the menu popup
  }
}

.MapPopupList {
  //width: 500px;

  .main-info {
    text-align: center;
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 0.3em;
  }

  .RowItem {
    //width: 400px;

    .item-name {
      display: inline-block;
      padding-left: 8px;

      .selected {
        display: inline-block;
        white-space: nowrap;
        max-width: 175px;
        //font-weight: bold;
      }
    }

    .RankBar {
      $height: 6px;
      $swell: 2px;
      display: inline-block;

      .bar {
        border: 1px solid #999999;
        background-color: #cccccc;
        height: $height;
        border-radius: $height/2;
      }

      .marker {
        position: absolute;
        top: -$swell/2;
        border-radius: 50%;
        width: $height + $swell;
        height: $height + $swell;
        background-color: #333333;
      }


      &.selected {
        $swell: 4px;

        .bar {
          border: 1px solid #666666;
        }

        .marker {
          background-color: red;
          border: 1px solid #666666;
          top: -$swell/2;
          width: $height + $swell;
          height: $height + $swell;
        }
      }
    }
  }
}
