.Hamburger {
    $size: 45px;
    $dot-size: $size / 2.5;

    width: $size;
    height: $size;
    text-align: center;

    button {
        @extend ._vertical-align;
        left: 0;
        right: 0;
        padding: 0;
        margin: auto;

        height: $size;
        width: $size;

        border: none;
        background: none;

        cursor: pointer;
        pointer-events: all;
        outline: none;
        transform: rotate(0deg);
        transition: transform .6s ease, width .5s ease, height .5s ease;
    }

    div {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        &:before, &:after {
            content: '';
            display: block;
            position: absolute;

            width: $dot-size;
            height: $dot-size;
            border-radius: 50%;

            background: #bcd;
        }

        &:nth-of-type(1) {
            z-index: 2;
            &:before {
                top: 0;
                left: 0;
                background: $color_brand-red;
            }

            &:after {
                bottom: 0;
                right: 0;
                background: $color_brand-yellow;
            }
        }

        &:nth-of-type(2) {
            opacity: 1;
            transition: opacity .4s;

            &:before {
                bottom: 0;
                left: 0;
            }

            &:after {
                top: 0;
                right: 0;
            }
        }
    }

    &.open {
        button {
            width: $dot-size * 2;
            height: $dot-size;

            transform: rotate(225deg);
            //transform: rotate(405deg);
            transition: transform .6s ease, width .5s ease, height .5s ease;
        }

        div:nth-of-type(2) {
            opacity: .6;
            transition: opacity .4s;
        }
    }

}
