.Logo {
    @extend ._primary-font;

    display: inline-block;

    color: $color_font-light;
    line-height: 1.1em;

    span {
        &:first-of-type { color: $color_brand-red; }
        &:nth-of-type(2) { color: $color_brand-yellow; }
        &:last-of-type { color: $color_brand-brown; }
    }

    &--small {
        font-size: $font_size-xsmall;
        @include respond-to(tablet) {
            font-size: $font_size-small;
        }
        background: $color_brand-brown;
        padding: .2em .2em .6em;
    }

    &--large {
        font-size: 24px;
        @include respond-to(s-mobile) {
            font-size: 20px;
        }
    }

}
