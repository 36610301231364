.ProjectsPage {

    .page-content {
        @include respond-to(desktop) {
            padding-left: inherit;
        }
        padding-bottom: 4em;
    }

    .projects {
        @include respond-to(desktop) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 25px;
        }
    }

    .project {
        margin-bottom: $dims_padding / 2;
        padding: $dims_padding / 2 $dims_padding;

        background: $color_bg-medium;
        border-radius: 15px;
        cursor: pointer;
        transition: background .2s;
        box-shadow: 3px 3px 18px rgba(0, 0, 0, 0.21);

        .big-quote {
            font-size: 1em;
            color: black;
        }

        @include respond-to-range(1200px, 1400px) {
            padding: 1rem 2rem;
        }

        .quote {
            text-shadow: 2px 2px 1.5px #ffffff;
            z-index: 1;
            font-size: 1.2em;

            @include respond-to-range(1200px, 1400px) {
                font-size: 1.05em;//tricky moment when boxes start to get long
            }
            @include respond-to(xl-desktop) {
                font-size: 1.5em;
            }
            color: black;
        }

        .organization {
            padding-top: 1em;
            text-shadow: 2px 2px 5px #ffffff;
            font-size: 0.8em;
            z-index: 1;
            font-weight: bold;

        }

        &:hover {
            transition: background .2s;
            background: darken($color_bg-medium, 5);

            h2 {
                opacity: .8;
                transition: opacity .2s;
            }
        }

        //&:nth-of-type(1) {
        //    height: 35vh;
        //}
        //&:nth-of-type(2) {
        //    height: 27vh;
        //}
        //&:nth-of-type(3) {
        //    height: 20vh;
        //}

        //@include respond-to(desktop) {
        //    //height: 50vh !important;
        //}

        a {
            display: block;

            width: 100%;
            height: 100%;

            color: $color_font-dark;
            text-decoration: none;

            &:hover {
                opacity: 1;
            }
        }

        img {
            @extend ._vertical-align;
            opacity: 0.25;
            top: 60%;
            height: 50%;
            max-width: 100%;

            @include respond-to(desktop) {
                top: 50%;
                height: auto;
                max-height: 85%;
            }
        }
    }

    h2 {
        font-size: $font_size-medium;
        opacity: 1;
        transition: opacity .2s;
    }

}
