.ContactForm {

    padding: 2em 0;

    textarea {
        width: 80vw;
        height: 30vw;
        padding: 0.5em;
    }
    label {
        margin-right: 1em;
    }

    input {
        padding: 0.5em;
    }

    @include respond-to(tablet) {
        padding: 2em;
        border: 1px solid #ededed;
        margin-top: 2em;

        input {
            margin-left: 1.5em;
            width: 400px;
            padding: 0.5em;
        }

        textarea {
            width: 600px;
            height: 180px;
            padding: 0.5em;
        }
    }


    .form-row {
        padding-top: 2em;

        button {
            font-weight: bold;
            font-size: 1em;
            background-color: #333333;
            color: white;
            border-radius: 15px;
            padding: 1em 1.5em ;
        }
    }


}
