.ContentPage {

    padding: $dims_padding $dims_padding / 2;

    @include respond-to(tablet) {
        padding: $dims_padding;
    }

    .Navigation {
        position: absolute;
        right: $dims_padding / 2;
        top: $dims_padding / 2;

        @include respond-to(tablet) {
            right: $dims_padding;
            top: $dims_padding;
        }
    }

    .Logo {
        color: $color_brand-brown;
        font-size: $font_size-xsmall;

        @include respond-to(lg-desktop)  {
            font-size: $font_size-small;
        }
    }

    .page-content {
        min-height: 76vh;
        padding: $dims_padding / 4;

        @include respond-to(tablet) {
            padding: 0;
            padding-left: 80px;
        }

        @include respond-to(lg-desktop) {
            padding-left: 150px;
            padding-right: 350px;
        }
    }

    header {
        margin-bottom: 25px;
        @include respond-to(tablet) {
            margin-bottom: 35px;
        }
    }

    footer {
        width: 100%;
        pointer-events: none;

        @include respond-to(mobile) {
            margin-top: $dims_padding;
        }

        @include respond-to(tablet) {
            position: fixed;
            bottom: $dims_padding;
            width: calc(100% - #{2*$dims_padding});
        }

        & * { pointer-events: all; }

        .footer-links {
            position: absolute;
            right: 0;
            bottom: 0;

            text-align: right;
            color: $color_font-light !important;

            @include respond-to(tablet) {
                display: none;
            }
            @include respond-to(lg-desktop) {
                display: block;
            }

            a {
                display: block;
                @include respond-to(lg-desktop) {
                    display: inline;
                }
            }

            .sasaki {
                top: 10px;
                height: 15px;

                @include respond-to(lg-desktop) {
                    height: auto;
                    width: 125px;
                    margin-bottom: 14px;
                }
            }

            .nrpa {
                height: 40px;

                @include respond-to(lg-desktop) {
                    width: 175px;
                    height: auto;
                }
            }
        }
    }
}


