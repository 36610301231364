.LandingPage {

  width: 100%;
  height: 100vh;

  background-image: url('../../assets/images/landing-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: $dims_padding / 2;

  @include respond-to(s-mobile) {
    padding: $dims_padding / 4;
  }

  @include respond-to(tablet) {
    padding: $dims_padding;
  }

  .selected-match {
    background: #333333;
  }

  .page-content {
    @extend ._vertical-align;
    top: 40%;
    width: calc(100vw - #{$dims_padding});

    color: $color_font-light;

    h1 {
      font-size: 2rem;
    }

    @include respond-to(s-mobile) {
      width: calc(100vw - #{$dims_padding/2});
      top: 35%;
      padding: 0; //$dims_padding / 4;
      h1 {
        font-size: 1.6rem;
      }
    }

    @include respond-to(tablet) {
      top: 50%;
      padding: $dims_padding / 2;
    }


    @include respond-to(desktop) {
      padding-left: 55px;

      width: initial;
    }
    @include respond-to(lg-desktop) {
      padding-left: 198px;
      h1 {
        font-size: $font_size-xlarge;
      }
    }
    @include respond-to(xl-desktop) {
      padding-left: 250px;
    }
    //< 1100
  }

  h1 {
    display: inline-block;
    font-size: $font_size-large;

    span {
      &:first-of-type {
        color: $color_brand-red;
      }

      &:last-of-type {
        color: $color_brand-yellow;
      }
    }

    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;

      width: 104%;
      height: .85em;

      background: $color_brand-brown;
    }
  }

  .search-bar {
    width: 100%;
    max-width: 750px;
    margin-top: 2em;

    font-size: $font_size-small;

    @include respond-to(s-mobile) {
      transform: scale(0.9) translate(-18px, 0);
    }
  }

  .search-input {
    display: flex;

    background: $color_bg-light;
    border-radius: 0;

    .carrot {
      flex: 1 1 20px;
      z-index: 2;

      padding: .6em 1em .8em;
    }

    //input {
    //    flex: 1 1 auto;
    //
    //    width: 100%;
    //    padding: .8em 0;
    //
    //    border: none;
    //}
  }

  .search-footer {
    @extend ._clear;

    margin-top: .5em;

    .analyze {
      display: none;

      @include respond-to(tablet) {
        display: inline;
      }
    }

    .explore {
      font-size: 1.1em;

      @include respond-to(s-mobile) {
        transform: scale(0.9) translate(-18px, 0);
      }
      @include respond-to(mobile) {
        transform: scale(0.9) translate(-18px, 0);
      }

      @include respond-to(tablet) {
        float: right;
        font-size: 1.2em;
      }

      background-color: #022133;
      padding: 0.25em 0.75em;
      width: 415px;

      span:not(.carrot) {
        display: inline-block;
        //margin-right: 1em;
        opacity: 0.75;
      }

      a {
        margin-left: 1em;
      }
    }
  }

  .about-link {
    position: absolute;
    top: $dims_padding / 2;
    right: $dims_padding / 2;

    .about-text {
      margin-left: 10px;
      vertical-align: top;
      padding-top: 2px;
      display: none;
      @include respond-to(tablet) {
        display: inline-block;
      }
    }

    @include respond-to(tablet) {
      top: $dims_padding;
      right: $dims_padding;
    }

    cursor: pointer;
    opacity: .85;
    transition: opacity .15s;

    &:hover {
      opacity: 1;
      transition: opacity .15s;
    }

    img {
      width: 24px;
    }
  }

  footer {
    position: absolute;
    bottom: $dims_padding;

    width: calc(100% - #{$dims_padding});
    @include respond-to(tablet) {
      width: calc(100% - #{$dims_padding});
    }

    .footer-links {
      position: absolute;
      right: 8px;
      bottom: 0;

      margin: 0;
      padding-left: 0;

      color: $color_font-light !important;
      list-style: none;
      text-align: right;

      .separator {
        display: none;
        @include respond-to(tablet) {
          display: inline;
        }
      }

      li {
        @include respond-to(tablet) {
          display: inline;
        }
      }
    }
  }


  .react-autosuggest__container {
    //position: relative;
    flex: 1 1 auto;
    width: 100%;
  }

  .react-autosuggest__input {
    padding: 1em 0;
    min-width: 350px;
    border: none;
    font-size: $font_size-small;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: $color_brand-brown;
    //font-family: Helvetica, sans-serif;
    //font-weight: 300;
    //font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
    color: $color_brand-brown;
    font-weight: bold;
  }

}
