
.map-link {
    a {
        color: #333333;
        margin-right: 1.5em;
    }
}

.StrategyPageContent {

    h2 {
        margin-bottom: .2em;

        @include respond-to(mobile) {
            font-size: $font_size-small;
        }
    }

    header p {
        @include respond-to(desktop) {
            font-size: $font_size-medium;
            width: 70%;
        }
    }

    .strategy {
        margin-bottom: 30px;

        &.pom {
            $color: #FAAF16;
            h2 { color: $color; }
            a { background: $color; }
            b { color: $color; }
        }

        &.ef {
            $color: #24AAA3;
            h2 { color: $color; }
            a { background: $color; }
            b { color: $color; }
        }

        &.ua {
            $color: #8CC63E;
            h2 { color: $color; }
            a { background: $color; }
            b { color: $color; }
        }

        &__content {
            @include respond-to(tablet) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: $dims_padding / 4;
                grid-row-gap: $dims_padding / 4;
            }

            @include respond-to(desktop) {
                grid-column-gap: $dims_padding / 2;
                grid-row-gap: $dims_padding / 2;
            }
        }

        &__column {
            @include respond-to(mobile) {
                margin-top: $dims_padding / 2;
            }
            padding: $dims_padding / 2;

            background: $color_bg-medium;
            border-radius: 15px;
        }

        //from 1023px-1200px use side-by-side-mode
        //from 1200px-1550px, the columns get too tight and need to be handled more like mobile
        //beyond 1550px, go back to side-by-side

        $desktopMin: 1023px;
        $narrowGridMin: 1199px;
        $narrowGridMax: 1550px;

        .img {
            height: 135px;
            width: 100%;

            @mixin side-by-side-mode() {
                display: inline-block;
                width: 40%;
            }

            @include respond-to-range($desktopMin, $narrowGridMin) {
                @include side-by-side-mode;
            }
            @include respond-to-gt($narrowGridMax) {
                @include side-by-side-mode;
            }
        }

        &__item {
            @mixin side-by-side-mode() {
                display: inline-block;
                margin-left: 4%;
                width: 55%;
                vertical-align: top;
            }

            @include respond-to-range($desktopMin, $narrowGridMin) {
                @include side-by-side-mode;
            }
            @include respond-to-gt($narrowGridMax) {
                @include side-by-side-mode;
            }
        }

        a {
            display: block;

            //width: 90%;
            margin: .6em 0;
            padding: .2em .5em;

            font-family: $font_family-primary;
            font-size: $font_size-xsmall;
            text-align: center;
            text-decoration: none;

            border-radius: 15px;
        }

        b {
            font-family: $font_family-primary;
        }
    }

}
