.ProjectPage {
  padding-bottom: $dims_padding * 2;

  h1 {
    margin-top: 1.5em;
    margin-bottom: 0px;
  }

  .location {
    margin-top: 0px;
    color: #9E405A;
  }

  p {
    @include respond-to(desktop) {
      width: 80%;
    }
  }

  h3.organization {
    margin-top: 0;
    color: #999999;
  }

  .threats {
    &.footer {
      padding-top: 2em;
    }

    button {
      padding: 0;
      margin: 0 0 0 0.5em;
      text-decoration: underline;

      font-weight: 400;
      font-size: 1em;
    }
  }

  .image-container {
    position: relative;
    @include respond-to(tablet) {
      //height: 460px;
    }

    border: 2px solid #9E405A;
    border-radius: 15px;
    background: $color_bg-medium;
    background-size: cover;

    width: 100%;

    div.aspect-maintainer {
      position: relative;
      padding-bottom: calc(100% * 585 / 1200);
    }
  }

}
