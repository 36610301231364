@import './Hamburger';

.Navigation {
    z-index: 999;

    .Hamburger {
        z-index: 4;
        top: -22px;

        @include respond-to(tablet) {
            display: none;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        .carrot {
            display: inline-block;
            font-weight: bold;
            margin-right: .25em;
        }

        a {
            color: $color_brand-brown;
        }

        @include respond-to(tablet) {
            display: inline-block;

            & + li {
                margin-left: $dims_padding;
            }
        }
    }

    @include respond-to(mobile) {
        ul {
            z-index: 2;
            position: fixed;
            left: 0;
            top: -50px;

            width: 100vw;
            height: 100vh;

            background: $color_bg-light;
            pointer-events: none;
            opacity: 0;
            transition: opacity .4s, top .5s;

            li {
                height: 15vh;

                font-size: $font_size-medium;
                text-align: center;

                &:first-of-type {
                    margin-top: 18vh;
                }
            }
        }

        &.open {
            ul {
                opacity: 1;
                top: 0px;
                pointer-events: all;

                transition: opacity .4s, top .5s;
            }
        }
    }

}
