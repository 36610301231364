.StrategiesPage {

  .page-content {
    @include respond-to(tablet) {
      margin: 35px auto 0;
    }
  }

  ul {
    padding-left: 0;
  }

  li {
    list-style: none;
  }

  .strategies {
    @include respond-to(lg-desktop) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 5%;
    }

    h2 {
      text-align: center;
      font-size: $font_size-medium * 1.5;

      @include respond-to(lg-desktop) {
        min-height: 3em;
      }
    }
  }

  .strategy-list {
    margin-bottom: $dims_padding;

    @include respond-to(lg-desktop) {
      margin-bottom: 0;
    }

    li {
      width: 90%;
      margin: 5% auto;

      @include respond-to(tablet) {
        margin: 2% auto;
      }

      @include respond-to(lg-desktop) {
        margin: 5% auto;
      }

      background: #555;
      border-radius: 15px;
      overflow: hidden;
    }

    a {
      display: block;

      padding: 1em;

      font-family: $font_family-primary;
      text-align: center;
      text-decoration: none;

      &:hover {
        opacity: .85;
      }
    }
  }

}
