/* Fonts */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto+Slab:wght@300;400&display=swap');


/* Variables */

$color_bg-light: #fff;
$color_bg-medium: #f3f3f3;

$color_brand-brown: #2c1608;
$color_brand-red: #b42626;
$color_brand-yellow: #e99113;

$color_font-light: #fff;
$color_font-dark: #333;

$dims_padding: 3rem;

$font_family-primary: 'Poppins', Arial, sans-serif;
$font_family-secondary: 'Roboto Slab', Times, serif;

$font_size-base: 16px;
$font_size-xsmall: .875rem;  // 14px
$font_size-small: 1rem;      // 16px
$font_size-medium: 1.125rem; // 18px
$font_size-large: 1.875rem;  // 30px
$font_size-xlarge: 2.5rem;  // 40px


/* Extends */


._clear {
    &:after {
        content: '';
        display: block;
        height: 0px;
        clear: both;
    }
}

._vertical-align {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
}

._primary-font {
    font-family: $font_family-primary;
    font-weight: 600;
}

._secondary-font {
    font-family: $font_family-secondary;
    font-weight: 300;
}


/* Mixins */

@mixin respond-to-range($min, $max) {
  @media all and (min-width: $min) and (max-width: $max) { @content; }
}
@mixin respond-to-gt($min) {
  @media all and (min-width: $min) { @content; }
}
@mixin respond-to($media) {
  @if $media == s-mobile {
    @media all and (max-width: 360px) { @content; }
  }
  @else if $media == mobile {
    @media all and (max-width: 640px) { @content; }
  }
  @else if $media == tablet {
    @media all and (min-width: 641px) { @content; }
  }
  @else if $media == desktop {
    @media all and (min-width: 1023px) { @content; }
  }
  @else if $media == lg-desktop {
    @media all and (min-width: 1199px) { @content; }
  }
  @else if $media == xl-desktop {
    @media all and (min-width: 1599px) { @content; }
  }
}


/* Globals */

* {
    position: relative;
    box-sizing: border-box;
}

html, body {
    @extend ._secondary-font;

    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;

    color: $color_font-dark;
    font-size: $font_size-base;

    overflow-x: hidden;
}

#root, .App {
    min-height: 100vh;
    min-width: 100vw;
}

a {
    @extend ._secondary-font;
    color: $color_font-light;

    opacity: 1;
    transition: opacity .15s;

    &:hover {
        opacity: .8;
        transition: opacity .15s;
    }
}

button {
    background: none;
    border: none;
    cursor: pointer;
}

h1 {
    font-size: $font_size-large;

    @include respond-to(tablet) {
        font-size: $font_size-xlarge;
    }
}

h2 {
    font-size: $font_size-medium;
    @include respond-to(tablet) {
        font-size: $font_size-large;
    }
}

h1, h2, h3 {
    @extend ._primary-font;
}

span.carrot {
    color: $color_brand-yellow;
    font-family: $font_family-secondary;
    font-size: $font_size-medium;
    font-weight: 400;
}


/* App Imports */

@import 'components/all';
@import 'routes/all';
